<template>
  <div class="bg-gray-500">
    <!-- Logo in the center -->
    <v-row justify="center" align="center" class="logo-container">
            <v-btn

    width="150px"

        :class="{ active: activeStream === 'channels' }"
        color="#23ffe5"
   
        @click="setStream('channels')"
      >
        Channels
      </v-btn>
      <v-btn
      width="150px"

        class="switch-btn"
        :class="{ active: activeStream === 'sports' }"
        color="white"

        @click="setStream('sports')"
      >
      Live Sport
      </v-btn>

    </v-row>

    <!-- Buttons to switch iframe -->
    <v-row justify="center">

    </v-row>

    <!-- Iframe container -->
    <div class="iframe-container">
      <div class="iframe-cropper">
        <iframe
          :src="iframeSrc"
          allowfullscreen
        ></iframe>
      </div>
    </div>

    <!-- Dialog for Ad Blocker Instructions -->
    <v-dialog
      v-model="adBlockerDialog"
      max-width="600px"
      style="overflow-x: hidden; overflow-y: auto;"
    >
      <v-card
        dark
        style="border-radius:20px;background-color:black; text-align:center;overflow-x: hidden; overflow-y: auto;"
      >
        <v-card-title class="justify-center">
          <v-img
            src="../assets/logo1.png"
            alt="Logo"
            height="200px"
            contain
          ></v-img>
        </v-card-title>
        <v-row justify="center">
          <v-card-title class="text-h5">Install uBlock Origin</v-card-title>
        </v-row>
        <v-card-text>
          <p>
            It seems like you don't have an ad blocker installed. We recommend
            using uBlock Origin for a better browsing experience. Follow the
            steps below to install it on your browser:
          </p>
          <h3>For Chrome</h3>
          <ol class="text-left" style="display: inline-block;">
            <li>
              Open the
              <a
                href="https://chrome.google.com/webstore/detail/ublock-origin/cjpalhdlnbpafiamejdnhcphjbkeiagm"
                target="_blank"
              >
                Chrome Web Store
              </a>.
            </li>
            <li>Search for "uBlock Origin".</li>
            <li>Click "Add to Chrome".</li>
            <li>Confirm by clicking "Add extension".</li>
          </ol>
          <h3>For Firefox</h3>
          <ol class="text-left" style="display: inline-block;">
            <li>
              Open the
              <a
                href="https://addons.mozilla.org/en-US/firefox/addon/ublock-origin/"
                target="_blank"
              >
                Firefox Add-ons
              </a>.
            </li>
            <li>Search for "uBlock Origin".</li>
            <li>Click "Add to Firefox".</li>
            <li>Confirm by clicking "Add".</li>
          </ol>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="onAdBlockerDialogClose" color="white" text>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeStream: "channels", // Tracks the current active stream
      iframeSrc: "https://rivestream.live/iptv", // Default iframe URL
      adBlockerDialog: false,
    };
  },
  methods: {
    setStream(stream) {
      this.activeStream = stream;
      this.iframeSrc =
        stream === "channels"
          ? "https://rivestream.live/iptv"
          : "https://rivestream.live/livesports";
    },
    checkAdBlocker() {
      if (!localStorage.getItem("adBlockerDialogShown")) {
        const adUrl = "https://example.com/ad.js";
        const testAd = document.createElement("img");
        testAd.src = adUrl;
        testAd.style.display = "none";
        testAd.onerror = () => {
          this.adBlockerDialog = true;
          testAd.remove(); // Cleanup after detection
        };
        testAd.onload = () => testAd.remove(); // Cleanup if no ad blocker
        document.body.appendChild(testAd);
      }
      localStorage.setItem("adBlockerDialogShown", "true");
    },
    onAdBlockerDialogClose() {
      this.adBlockerDialog = false;
    },
  },
  mounted() {
    this.checkAdBlocker();
  },
};
</script>

<style scoped>
.v-btn {
  margin: 10px 0;
}
.bg-gray-500 {
  background-color: #1b1919; /* Tailwind CSS gray-500 */
  width: 100%;
  height: 100vh;
}
.logo-container {
  position: fixed;
  top: 8%;
  left: 55%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.logo {
  width: 150px; /* Adjust logo size */
  margin-top:10%;
  height: 160px; /* Maintain square aspect ratio */
}
.iframe-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
.button-row {
  margin-top: 20px;
  z-index: 100; /* Ensure it's above the iframe */
}
.switch-btn {
  margin: 0 10px;
  font-weight: bold;
}
.switch-btn.active {
  background-color: #ffffff; /* Active button background */
  color: black; /* Active button text color */
}
.iframe-cropper {
  position: absolute;
  top: 12%; /* Adjust as needed */
  left: 13%; /* Adjust as needed */
  width: 85%; /* Width of the cropped area */
  height: 90%; /* Height of the cropped area */
  overflow: hidden; /* Crop overflowing content */
}
.iframe-cropper iframe {
  position: absolute;
  top: -70px; /* Offset to align content within the cropped area */
  left: -64px; /* Offset to align content within the cropped area */
  width: 100%; /* Ensure the iframe covers the cropped area */
  height: 120%; /* Ensure the iframe covers the cropped area */
  border: none;
}

/* Remove left margin and adjust styles on mobile */
@media (max-width: 768px) {
  .iframe-container {
    left: 0;
    width: 100%;
  }
  .iframe-cropper {
  position: absolute;
  top: 12%; /* Adjust as needed */
  left: 13%; /* Adjust as needed */
  width: 85%; /* Width of the cropped area */
  height: 90%; /* Height of the cropped area */
  overflow: hidden; /* Crop overflowing content */
}
.iframe-cropper iframe {
  position: absolute;
  top: -55px; /* Offset to align content within the cropped area */
  left: -10px; /* Offset to align content within the cropped area */
  width: 100%; /* Ensure the iframe covers the cropped area */
  height: 120%; /* Ensure the iframe covers the cropped area */
  border: none;
}

}
</style>
